.calendarWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  border-radius: 10px;
  height: 286px;
  color: #333333;
  bottom: 0;
  right: -1px;
  gap: 6px;

  @media (max-width: 768px) {
    height: 246px;
    right: -1px;
    gap: 2px;
  }
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 00 10px 30px rgba(0, 0, 0, 0.1);
  height: 246px;
  color: #333333;
  top: -285px;
  right: 0;
  gap: 14px;
  width: 246px;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 170px;
  }
}

.calendarStyleWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  border-radius: 16px;
  height: 625px;
  width: 408px;
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%);
  color: #333333;
  top: 43px;
  left: -43px;
  gap: 48px;
  z-index: 5;
}

.datePickerHeader {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #f5edf0;
  color: #383f43;

  @media (max-width: 768px) {
    padding: 8px 0;
  }
}

.datePickerHeaderCalendar {
  padding: 20px 0;
  text-align: center;
  border-bottom: 3px solid #f5edf0;
  color: #383f43;
  font-size: 20px;
}

.datePickerContainer {
  display: flex;
  height: 166px;
  position: relative;
  padding-left: 10px;

  .selector {
    width: 100%;
    height: 22px;
    background-color: #e4e4e4;
    top: 40%;
    left: 0px;
    border-radius: 5px;
    position: absolute;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
}

.datePickerContainerCalendar {
  display: flex;
  height: 310px;
  justify-content: space-evenly;
}
