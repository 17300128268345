.container {
  width: 38px;
  height: 28px;
  background: #a0627b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.1s;
}

.iconWrapper {
  width: 16px;
  height: 16px;
  position: relative;
  background-color: #f9f9f9;
  border-radius: 50%;
}

.selectedIcon {
  background-color: #a0627b;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.color {
  path {
    fill: #ffffff;
  }
}

.colorStroke {
  path {
    stroke: #ffffff;
  }
}

.selected {
  background: #ffffff;
  border: 0.5px solid #a0627b;
  width: fit-content;
  padding: 4px 12px;
  color: #383f43;
  font-size: 14px;
  line-height: 19px;
}

.error {
  background: red;
}
