.containerMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.label {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.buttonWrapper {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.phoneInput {
  gap: 8px;
  padding: 20px 0 24px 0;

  &.PhoneInput div.PhoneInputCountry div.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 5px;
    height: 10px !important;
    margin-left: 10px;
    border-style: solid;
    border-color: #383f43;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
  }
}

.phoneInput input:focus {
  background-color: #f9f9f9;
}

@mixin activeContainer() {
  border-radius: 95.652px;
  border: 1px solid #FF9536;
  background: #F5F3F9;
  span{
    display: none;
  }

  @media (max-width: 768px) {
    padding-bottom: 4px;
  }
}

@mixin activeLabel() {
  transform: translate(0, -35px);
  border: 0;
  padding-bottom: 0;

  @media (max-width: 768px) {
    transform: translate(0, -25px);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  .inputWrapper {
    height: 46px;
    padding: 16px 15px 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    &.reversed {
      direction: ltr;
    }
    background: #ffffff;
    will-change: border, box-shadow;
    transition: box-shadow 0.2s ease-out, border 0.2s ease-out,
      height 0.2s ease-out;

    &.wrapperDefault {
      background: unset;
    }
  }

  &.wrapperDefault .inputWrapper {
    background: unset;
  }

  .inputWrapper:focus-within {
    background: unset;
  }

  &.boxShadow .inputWrapper {
    height: 46px;
    padding: 16px 15px 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    //border-radius: 12px;
    border-radius: 95.652px;
    background: #E6E4ED;

    .input {
      line-height: 19px;
    }
  }

  &.line .inputWrapper {
    height: 39px;
    padding: 15px 0 5px;
    border-bottom: 1px solid #ede4e8 !important;
    line-height: 14px;
  }

  &.lineText {
    &.line .inputWrapper {
      border-bottom: unset !important;
    }
  }

  &.containerError {
    &.boxShadow .inputWrapper {
      border: 0.5px solid #ff4040;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    }

    &.line .inputWrapper {
      border-bottom: 1px solid #ff4040 !important;
    }
  }

  &.noHeading .inputWrapper {
    @include activeContainer();
  }

  &:focus-within {
    &.boxShadow .inputWrapper {
      @include activeContainer();
    }

    &.line .inputWrapper {
      height: 65px;
      border-bottom: 1px solid #ede4e8 !important;
    }
  }

  &:not(:focus-within) {
    &.noBorderBottom .inputWrapper {
      border-bottom: unset !important;
    }

    .activeLabelText {
      display: none;
    }
  }

  &:focus-within .error {
    display: none;
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: #ca6a6a;
  user-select: none;
}

.label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  position: relative;
  padding-right: 8px;
  padding-bottom: 4px;

  .labelText {
    position: absolute;
    padding-bottom: 4px;
    padding-right: 8px;
    pointer-events: none;
    font-size: 12px;
    line-height: 14px;
    color: #b79ba6;
    will-change: transform;
    transition: transform 0.2s ease-out;
  }

  .noHeadingLabel {
    display: block !important;
    @include activeLabel();
  }

  &:focus-within {
    .labelText {
      @include activeLabel();
    }
  }
}

.input {
  width: 100%;
  font-size: 14px;
  caret-color: #acacac;
  background: transparent;
  color: #383f43;
  outline: none;

  &:focus {
    background: transparent;
    border: 1px solid transparent;
  }
}

.labelExtra {
  margin-left: 50px;
  font-weight: initial !important;
}

.btnContinue{
  width: 100%;
  border-radius: 49.953px;
  background: #FF9536;
  color: #630E0E;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.btnContinue:hover {
  background: #FF9536;
}