.chipsWrapper {
  margin: 10px 0;
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  &.down {
    top: -10px;
  }

  &.up {
    bottom: -10px;
  }

  @media (min-width: 768px) {
    margin: 0;
    position: initial;
    justify-content: space-between;
  }
}
