.cardContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 0.5px solid red;
}

.labelPrefix {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
