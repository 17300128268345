@use 'styles' as *;

.inputWrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.input {
  width: 100%;
  border-radius: 95.652px;
  background: #F5F3F9;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #a0627b;
  padding: 15px 15px 15px 169px;

  &.reversed {
    @media (max-width: 768px) {
      padding-left: 140px;
    }
  }
}

.input::-webkit-calendar-picker-indicator {
  display: none;

}

.birth {
  //font-size: 14px;
  left: 40%;
  //color: #333333;

  color: #AC9CB5;
  text-align: right;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.calendar {
  right: 40%;
  cursor: pointer;
  z-index: 10;
}

.position {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.pickerContainer {
  position: absolute;
  z-index: 9999999;
}
.reversed {
  direction: ltr;
  .calendar {
    left: 20px;
    right: unset;
  }
  .birth {
    right: 16px;
    left: unset;
  }
}
.wrapperReversed {
  right: 240px;
}
