.text {
  font-family: inherit;
}

.fontStyleItalic {
  font-style: italic;
}

.fontStyleNormal {
  font-style: normal;
}

.fontWeightNormal {
  font-weight: 400;
}

.fontWeightBold {
  font-weight: 700;
}

.fontWeightSemiBold {
  font-weight: 500;
}

.fontWeightThin {
  font-weight: 200;
}

.fontSize_xxs {
  font-size: 8px;
  line-height: 11px;
}

.fontSize_xs {
  font-size: 10px;
  line-height: 14px;
}

.fontSize_sm {
  font-size: 12px;
  line-height: 16px;
}

.fontSize_base {
  font-size: 14px;
  line-height: 19px;
}

.fontSize_lg {
  font-size: 15px;
  line-height: 20px;
}

.fontSize_xl {
  font-size: 16px;
  line-height: 22px;
}

.textColor_white {
  color: #fff;
}

.textColor_black {
  color: #000;
}

.textColor_gray_dark {
  color: #333333;
}

.textColor_gray_light {
  color: #383f43;
}

.textColor_blue_dark {
  color: #2e3940;
}

.textColor_pink_dark {
  color: #a0627b;
}

.textColor_pink_light {
  color: #b79ba6;
}

.textColor_green {
  color: #57b88b;
}

.textAlign_left {
  text-align: left;
}

.textAlign_center {
  text-align: center;
}

.textAlign_right {
  text-align: right;
}

.textAlign_justify {
  text-align: justify;
}

.textTransform_uppercase {
  text-transform: uppercase;
}

.textTransform_lowercase {
  text-transform: lowercase;
}

.textTransform_capitalize {
  &::first-letter {
    text-transform: uppercase;
  }
}

.textTransform_normal {
  text-transform: none;
}

.disabled {
  color: #d3b8c2;
}
