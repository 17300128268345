@mixin buttonDesktop() {
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 1px 4px rgba(99, 93, 96, 0.3);
  font-family: inherit;
  transition: 0.3s all;
  cursor: pointer;
}

.palettesPrimary {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  background-color: #a0627b;

  &:hover {
    background-color: #9b3d63;
    @media (min-width: 768px) {
      & {
        box-shadow: 2px 1px 10px rgba(99, 93, 96, 0.3);
      }
    }
  }

  &:active {
    background-color: #a0627b;
  }

  &:disabled {
    color: #d3b8c2;
    background-color: #f5f2f2;
    box-shadow: 2px 1px 4px rgba(1, 1, 1, 0.3);
  }
}

.palettesPink {
  color: #ff8282;
  font-size: 8px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  background-color: #fff;
  border: 0.3px solid #ff8282;
  &:hover {
    background-color: #ff8282;
    color: #fff;
  }

  &:active {
    background-color: #a0627b;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    color: #ff8282;
    background-color: #fff;
    box-shadow: 2px 1px 4px rgba(1, 1, 1, 0.3);
  }
}

.palettesPurpose {
  color: #ba8ef2;
  font-size: 8px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  background-color: #fff;
  border: 0.3px solid #ba8ef2;
  &:hover {
    background-color: #ba8ef2;
    color: #fff;
  }

  &:active {
    background-color: #a0627b;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    color: #ba8ef2;
    background-color: #fff;
    box-shadow: 2px 1px 4px rgba(1, 1, 1, 0.3);
  }
}

.palettesGreen {
  color: #82e280;
  font-size: 8px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  background-color: #fff;
  border: 0.3px solid #82e280;
  &:hover {
    background-color: #82e280;
    color: #fff;
  }

  &:active {
    background-color: #a0627b;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    color: #82e280;
    background-color: #fff;
    box-shadow: 2px 1px 4px rgba(1, 1, 1, 0.3);
  }
}

.palettesSecondary {
  color: #a0627b;
  font-size: 16px;
  line-height: 22px;
  background-color: #f5f2f2;
  box-shadow: 2px 1px 4px rgba(99, 93, 96, 0.3);
  z-index: 1;
  &:hover {
    color: #9b3d63;
    @media (min-width: 768px) {
      & {
        box-shadow: 2px 1px 10px rgba(99, 93, 96, 0.3);
      }
    }
  }

  &:active {
    color: #a85c79;
    box-shadow: inset 1px 1px 4px rgba(99, 93, 96, 0.15);
  }

  &:disabled {
    color: #d3b8c2;
    background-color: #f5f2f2;
    box-shadow: none;
  }
}

.palettesBeige {
  color: #fffef5;
  font-size: 12px;
  background-color: #c1c0a4;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
  @include buttonDesktop();
}

.palettesLilac {
  color: #ffffff;
  font-size: 12px;
  background-color: #cf66ff;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
  @include buttonDesktop();
}

.palettesDarkGray {
  color: #ffffff;
  font-size: 12px;
  background-color: #515154;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
  @include buttonDesktop();
}

.palettesClearBeige {
  color: #c1c0a4;
  font-size: 12px;
  background-color: transparent;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
  border: 0.2px solid #515154;
  @include buttonDesktop();
}

.palettesClear {
  color: #c1c0a4;
  font-size: 12px;
  background-color: transparent;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
  width: fit-content !important;
  height: fit-content !important;
  @include buttonDesktop();
}

.palettes {
  color: #515154;
  font-size: 12px;
  line-height: 15px;
  background-color: #515154;
  z-index: 1;
  font-weight: 200;
  text-transform: lowercase;
}

.palettes {
  color: #fffef5;
  font-size: 12px;
  line-height: normal;
  background-color: #c1c0a4;
  z-index: 1;
  &:hover {
    background-color: #515154;
  }

  &:active {
    background-color: #515154;
  }

  &:disabled {
    color: #d3b8c2;
    background-color: #f5f2f2;
    box-shadow: 2px 1px 4px rgba(1, 1, 1, 0.3);
  }
}

.sizesLittle {
  width: 28px;
}

.sizesFixed {
  width: 104px;
}

.sizesFill {
  width: 100%;
}

.sizesFlexible {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}

.roundingSmall {
  border-radius: 3.226px;
}

.roundingRegular {
  border-radius: 4px;
}

.roundingMedium {
  border-radius: 5px;
}

.roundingLarge {
  border-radius: 12px;
}

.heightsLittle {
  height: 14px;
}

.heightExtraSmall {
  height: 28px;
  @media (min-width: 768px) {
    height: 38px;
  }
}

.heightsSmall {
  height: 32px;

  @media (min-width: 768px) {
    height: 40px;
  }
}

.heightsRegular {
  height: 36px;

  @media (min-width: 768px) {
    height: 40px;
  }
}

.heightLarge {
  height: 40px;
}

// .imageMarginRight {
//   margin-right: 4px;
// }

.imageMarginLeft {
  margin-left: 4px;
}

.fontStyleItalic {
  font-style: italic;
}

.fontStyleNormal {
  font-style: normal;
}

.fontWeightNormal {
  font-weight: 400;
}

.fontWeightBold {
  font-weight: 700;
}

.fontSize_xxs {
  font-size: 8px;
  line-height: 11px;
}

.fontSize_xs {
  font-size: 10px;
  line-height: 14px;
}

.fontSize_sm {
  font-size: 12px;
  line-height: 16px;
}

.fontSize_base {
  font-size: 14px;
  line-height: 19px;
}

.fontSize_lg {
  font-size: 15px;
  line-height: 20px;
}

.fontSize_xl {
  font-size: 16px;
  line-height: 22px;
}

.textColor_white {
  color: #fff;
}

.textColor_black {
  color: #000;
}

.textColor_gray_dark {
  color: #333333;
}

.textColor_blue_dark {
  color: #2e3940;
}

.textColor_pink_dark {
  color: #a0627b;
}

.textAlign_left {
  text-align: left;
}

.textAlign_center {
  text-align: center;
}

.textAlign_right {
  text-align: right;
}

.textTransform_uppercase {
  text-transform: uppercase;
}

.textTransform_lowercase {
  text-transform: lowercase;
}

.textTransform_capitalize {
  text-transform: capitalize;
}

.textTransform_normal {
  text-transform: none;
}
