.datePickerSection {
  display: flex;
}
.monthSection {
  min-width: 118.3px;

  &.isPeriod {
    @media (max-width: 768px) {
      min-width: 60.3px;
    }
  }
}
.daySection {
  min-width: 51.5px;

  &.isPeriod {
    @media (max-width: 768px) {
      min-width: 41.5px;
    }
  }
}
.dateItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #a9a9a9;
  height: 22px !important;
  transition: color 0.2s ease-in-out;

  &.selected {
    color: #292929;

    font-size: 18px;
    padding: 0px;
    font-weight: 400;
  }
}

.days {
  width: 54px;
}

.daysSmall {
  @media (max-width: 768px) {
    width: 48px;
  }
}

.months {
  width: 114px;
}

.monthsSmall {
  @media (max-width: 768px) {
    width: 53px;
  }
}

.years {
  width: 78px;
}

.yearsSmall {
  @media (max-width: 768px) {
    width: 70px;
  }
}

.neighbors {
  font-size: 16px;
  line-height: 16px;
  color: #a9a9a9;
}
.neighborsTopRotate {
  transform: rotateX(40deg);
  transform-style: preserve-3d; /* Это гарантирует, что 3D-трансформации будут корректно отображаться */
  perspective: 1000px;
}
.prevNeighborsTopRotate {
  transform: rotateX(45deg);
  transform-style: preserve-3d; /* Это гарантирует, что 3D-трансформации будут корректно отображаться */
  perspective: 1000px;
}

.prevNeighborsBottomRotate {
  transform: rotateX(-45deg);
  transform-style: preserve-3d; /* Это гарантирует, что 3D-трансформации будут корректно отображаться */
  perspective: 1000px;
}
.neighborsBottomRotate {
  transform: rotateX(-45deg);
  transform-style: preserve-3d; /* Это гарантирует, что 3D-трансформации будут корректно отображаться */
  perspective: 1000px;
}

.prevNeighbors {
  font-size: 14px;
  line-height: 14px;
  color: #a9a9a9;
  background: linear-gradient(
    7.77deg,
    #b2b2b2 6.1%,
    rgba(178, 178, 178, 0) 122.06%
  );
  background-size: 3px 3px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paddingLeft {
  padding-left: 30px;
  perspective: 300px;
}

.paddingLeftNeighbor {
  padding-left: 15px;
}

.paddingRight {
  padding-right: 35px;
}

.paddingRightNeighbor {
  padding-right: 15px;
}
