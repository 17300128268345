@mixin activeContainer() {
  border-radius: 95.652px;
  border: 1px solid #FF9536;
  background: #F5F3F9;
  @media (max-width: 768px) {
    padding-bottom: 4px;
  }
}

@mixin activeLabel() {
  //transform: translate(0, -20px);
  //border: 0;
  //padding-bottom: 0;
  display: none;

  @media (max-width: 768px) {
    //transform: translate(0, -20px);
  }
}

@mixin beigeInputText() {
  font-size: 12px;
  color: #515154;
  line-height: 15px;
  font-weight: 200;
  @media (min-width: 600px) {
    font-size: 16px;
    line-height: 16px;
  }
}

@mixin beigeLabelFocus() {
  transform: none;
  color: #515154;
  transform: none;
  padding-bottom: 6px;
}

@mixin lineForInput() {
  content: '';
  display: block;
  height: 0.2px;
  background-color: #515154;
  position: absolute;
  left: 10px;
  bottom: 0;
  transform: translateY(-6px);
  @media (min-width: 768px) {
    transform: translateY(-8px);
  }
}

@mixin beigeLabelText() {
  position: static;
  color: #c1c0a4;
  font-size: 10px;
  font-weight: 200;
  line-height: 13px;
  text-transform: lowercase;
  display: block;
  padding-bottom: 6px;
  padding-right: 0;
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin beigeLabel() {
  flex-direction: column-reverse;
  align-items: flex-start;
  height: fit-content;
}

@mixin beigeInputFocus() {
  .focusInput {
    input {
      border: 0.2px solid #515154;
      background-color: #ffffff;
      line-height: 15px;
    }
  }
  ::placeholder {
    color: #c1c0a4;
  }
}

@mixin palettesInitial() {
  width: fit-content;
  background-color: transparent;
  position: relative;
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;
  .inputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    text-wrap: nowrap;
    background: #ffffff;
    will-change: border, box-shadow;
    transition: box-shadow 0.2s ease-out, border 0.2s ease-out,
      height 0.2s ease-out;

    &.wrapperDefault {
      background: unset;
    }
  }

  &.wrapperDefault .inputWrapper {
    background: unset;
  }

  .inputWrapper:focus-within {
    background: unset;
  }

  &.boxShadow .inputWrapper {
    height: 44px;
    padding: 10px 20px;
    border-radius: 95.652px;
    background: #F5F3F9;

    .input {
      line-height: 19px;
    }
  }

  &.line .inputWrapper {
    //height: 44px;
    //padding: 15px 0 5px;
    //border-bottom: 1px solid #ede4e8 !important;
    //line-height: 14px;

    height: 44px;
    padding: 10px 20px;
    border-radius: 95.652px;
    background: #F5F3F9;
  }

  //&.lineText {
  //  &.line .inputWrapper {
  //    border-bottom: unset !important;
  //  }
  //}

  &.containerError {
    &.boxShadow .inputWrapper {
      border: 0.5px solid #ff4040;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    }

    &.line .inputWrapper {
      border-bottom: 1px solid #ff4040 !important;
    }
  }

  &.noHeading .inputWrapper {
    @include activeContainer();
  }

  &:focus-within {
    &.boxShadow .inputWrapper {
      @include activeContainer();
    }

    &.line .inputWrapper {
      //height: 65px;
      border: 1px solid #FF9536
    }
  }

  &:not(:focus-within) {
    &.noBorderBottom .inputWrapper {
      border-bottom: unset !important;
    }

    .activeLabelText {
      display: none;
    }
  }

  &:focus-within .error {
    display: none;
  }

  z-index: 100;
  &.boxShadow .palettesBeige {
    width: fit-content;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    border: none;
    width: 100%;
    input {
      @include beigeInputText();
      border: 1px solid #515154;
      border-radius: 5px;
      background-color: transparent;
    }
    .labelText {
      @include beigeLabelText();
    }

    .label {
      @include beigeLabel();
      &:focus-within {
        .labelText {
          @include beigeLabelFocus();
        }
      }
    }
    @include beigeInputFocus();
  }

  &.boxShadow .palettesClearBeige {
    @include palettesInitial();
    input {
      @include beigeInputText();
      display: inline-block;
      border: 1px solid #515154;
      border-radius: 5px;
      background-color: transparent;
      width: 100%;
    }

    .labelText {
      @include beigeLabelText();
    }

    .label {
      @include beigeLabel();
      &:focus-within {
        .labelText {
          @include beigeLabelFocus();
        }
        &:before {
          @include lineForInput();
          width: calc(100% - 20px);
        }
      }
    }

    @include beigeInputFocus();
  }

  &.boxShadow .palettesWithoutBorder {
    @include palettesInitial();
    input {
      @include beigeInputText();
      display: inline-block;
      border: none;
      border-radius: 5px;
    }

    .labelText {
      @include beigeLabelText();
    }

    .label {
      @include beigeLabel();
      &:focus-within {
        .labelText {
          @include beigeLabelFocus();
        }
        &:before {
          @include lineForInput();
          width: calc(100% - 20px);
        }
      }
    }

    @include beigeInputFocus();
  }

  &.boxShadow .palettesWithoutBorderWithIcon {
    @include palettesInitial();
    input {
      @include beigeInputText();
      display: inline-block;
      border: none;
      border-radius: 5px;
      padding-left: 38px;
      @media (min-width: 768px) {
        padding-left: 48px;
      }
    }

    .labelText {
      @include beigeLabelText();
    }

    .label {
      @include beigeLabel();
      &:focus-within {
        .labelText {
          transform: none;
          color: #515154;
          transform: none;
          padding-bottom: 6px;
        }
        &:before {
          @include lineForInput();
          width: calc(100% - 48px);
          left: 38px;
          @media (min-width: 768px) {
            width: calc(100% - 58px);
            left: 48px;
          }
        }
      }
    }

    @include beigeInputFocus();
  }

  .heightsSmall input {
    height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    @media (min-width: 600px) {
      height: 38px;
    }
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: #ca6a6a;
  user-select: none;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  color: #AC9CB5!important;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  &.withIcon {
    height: 18px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .labelText {
    position: absolute;
    padding-bottom: 4px;
    padding-right: 8px;
    pointer-events: none;
    line-height: 14px;
    will-change: transform;
    transition: transform 0.2s ease-out;

    &.withIcon {
      left: 28px;
      top: 4px;
    }
  }

  .noHeadingLabel {
    display: block !important;
    @include activeLabel();
  }

  &:focus-within {
    .labelText {
      @include activeLabel();
    }
  }
  .fixedLabel {
    transform: none !important;
  }
  &:focus-within {
    .fixedLabel {
      display: none;
    }
  }
}

.input {
  width: 100%;
  font-size: 14px;
  caret-color: #acacac;
  background: transparent;
  color: #383f43;
  outline: none;

  &:focus {
    background: transparent;
    border: 1px solid transparent;
  }
}
