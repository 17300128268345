.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.containerNames{
  display: flex;
  gap: 10px;
}

.btnNext{
  width: 100%;
  border-radius: 49.953px;
  background: #FF9536;

  color: #630E0E;
  font-family: Montserrat;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}
.btnNext:hover{
  background: #FF9536;
}



.buttonWrapper {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  p{
    color: #51473C;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.label {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}
.genderReversed {
  gap: 7px;
  &::after {
    left: unset;
    right: 14.3px;
  }
}
.calendarWrapperReversed {
  right: 120px;
}

.signInButton {
  background: transparent;
  box-shadow: none;
  width: fit-content;
  height: 22px;
  white-space: nowrap;
  text-decoration-line: underline;
  color: #FF9536;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    box-shadow: none;
  }
}
