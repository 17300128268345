.container {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;

  @media (min-width: 768px) {
  }
}

.formRegistration{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1410px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 50px 0px rgba(135, 59, 57, 0.15);
  padding: 22px;
  @media (max-width: 1300px) {
    width: 90%;
  }
}

.imgRegistration{
  width: 46%;
  height: 741px;
  border-radius: 20px;
  object-fit: cover;
}

.containerChipsGroup{
  width: 54%;
  max-width: 519px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  h1{
    color: #51473C;
    font-family: Montserrat, sans-serif;
    font-size: 3rem;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
}

.mainContainer {
  width: 100%;
  padding: 3%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (min-width: 768px) {
    margin: 0;
    gap: 20px;
  }
}

.inputsPasswordBlock {
  padding: 4px 18px;
}

.cardContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.input {
  padding-right: 0;
  padding-left: 8px;
  box-shadow: none;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}

.entranceNumber,
.entranceCode {
  max-width: 156px;
}

.buttonWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.span {
  margin-bottom: 6px;
  color: #a79ca0;
  font-size: 15px;
  font-weight: 300;
}

.label {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.labelError {
  border-color: red;
}

.labelPrefix {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.phoneInput {
  gap: 8px;
  padding: 20px 0 24px 0;

  &.PhoneInput div.PhoneInputCountry div.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 5px;
    height: 10px !important;
    margin-left: 10px;
    border-style: solid;
    border-color: #383f43;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
  }
}

.phoneInput input:focus {
  background-color: #f9f9f9;
}

.separator {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #a0627b;
}

.errorText {
  margin-right: 12px;
  text-align: end;
  font-size: 12px;
  line-height: 16px;
  color: #ca6a6a;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px 13px;
}

.codesContainer {
  display: flex;
  gap: 20px;
}

.cheepContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.reversed {
  direction: rtl;
}
