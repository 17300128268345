@use 'styles' as *;

.container {
  display: flex;
  flex-direction: column;
  background-image: url("../../../assets/images/background-registration.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  p{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.title{
  position: relative;
  display: flex;
  div{
    position: absolute;
    width: 442.827px;
    height: 444.364px;
    transform: rotate(29deg);
    border-radius: 444.364px;
    background: linear-gradient(167deg, rgba(255, 231, 212, 0.00) 5.02%, rgba(255, 217, 186, 0.20) 90.9%);
    filter: blur(69.19169616699219px);
    backdrop-filter: blur(23.063899993896484px);
  }
  h1{
    padding: 13.96% 0 0 16.25%;
    color: #FFF;
    font-size: 128px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 1;
    @media (max-width: 1300px) {
      font-size: 100px;
    }
  }
}

.mainBlock{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 51px;
  margin: 5.09% 0 0 40.16%;
  width: 774px;
  height: 322px;
  border-radius: 120px 0px 0px 0px;
  background: #FFF;
  box-shadow: 0px 20px 100px 0px rgba(135, 59, 57, 0.15);
  z-index: 1;
  @media (max-width: 1300px) {
    margin: 5.09% 0 0 10%;
  }
  p{
    color: #8D673D;
    font-size: 32px;
  }
  button:nth-child(2){
    width: 360px;
    height: 60px;
    padding: 10px 30px;
    border-radius: 99.907px;
    background: #FF9536;
    color: #630E0E;
    font-size: 32px;
    font-style: italic;
    text-transform: uppercase;
    cursor: pointer;
  }
  button:nth-child(3){
    color: #FF9536;
    font-size: 30px;
    text-decoration-line: underline;
    background: none;
    cursor: pointer;
  }
}

.rightRectBlur{
  width: 409px;
  height: 323px;
  margin: -322px 0 0 auto;
  border: 2px solid rgba(246, 221, 174, 0.47);
  background: radial-gradient(199.64% 107.75% at 50.9% 12.93%, rgba(235, 122, 54, 0.70) 0%, rgba(255, 170, 118, 0.70) 100%);
  backdrop-filter: blur(10px);
}


.bottomRectBlur{
  position: absolute;
  bottom: 0;
  width: 774px;
  height: 358px;
  margin-left: 40.16%;
  border: 2px solid rgba(246, 221, 174, 0.47);
  background: radial-gradient(199.64% 107.75% at 50.9% 12.93%, rgba(235, 122, 54, 0.70) 0%, rgba(255, 170, 118, 0.70) 100%);
  backdrop-filter: blur(10px);
  @media (max-width: 1300px) {
    margin-left: 10%;
  }
}

