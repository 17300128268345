.buttonWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.button {
  background: #ffffff;
  box-shadow: 2px 1px 4px rgba(99, 93, 96, 0.3);
  color: #383f43;
  width: 80px;
  height: 32px;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.textColor {
  color: #a0627b;
  font-weight: 700;
}
