.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.buttonWrapper {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.btnCreate{
  width: 100%;
  border-radius: 49.953px;
  background: #FF9536;
  color: #630E0E;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.btnCreate:hover {
  background: #FF9536;
}

.input {
  //padding-right: 0;
  //
  //box-shadow: none;
  //border-radius: 0;
  //border-top: none;
  //border-left: none;
  //border-right: none;
  .label {
    color: bisque;
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}
.inputsPasswordBlock {
  padding: 4px 18px;
}
