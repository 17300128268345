.countriesFilter {
  box-shadow: 0px -14px 33px -11px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  width: 160px;
  padding-left: 6px;
  background-color: #f8f3f5 !important;
  border-bottom: 1px solid #ede4e8;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.selector {
  width: 160px;
  height: fit-content;
  min-height: 30px;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f8f3f5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    width: 3px; /* ширина скроллбара */
    height: 10px; /* высота скроллбара для горизонтального скроллинга */
  }

  /* Стилизация фона скроллбара (трека) */
  &::-webkit-scrollbar-track {
    background: #f8f3f5; /* цвет фона */
    border-radius: 10px; /* закругление углов */
  }

  /* Стилизация самого ползунка скроллбара */
  &::-webkit-scrollbar-thumb {
    background: #888; /* цвет ползунка */
    border-radius: 10px; /* закругление углов */
  }

  /* Изменение цвета ползунка при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* цвет ползунка при наведении */
  }
}
.listItem {
  height: 30px;
  padding: 6px 0;
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    @media (min-width: 768px) {
      & {
        background-color: #ffe6f1;
      }
    }
  }
}
.listItemDialCode {
  font-size: 12px;
  font-weight: 500;
}
.listItemCountryName {
  line-height: 11px;
  font-size: 12px;
  width: 80px;
}
.countrySelectorContainer {
  z-index: 1000;

  top: -285px;
  position: absolute;
}
.countrySelectorContainerFocused {
  @media (max-width: 768px) {
    top: -420px;
  }
}
.selected {
  background-color: #fdd7e8;
}
