.busyIndicator {
  position: absolute;
  z-index: 1000000;
  visibility: visible;

  .loaderContainer {
    position: fixed;
    width: 390px;
    height: 260px;
    top: calc(50vh - calc(260px / 2));
    left: calc(50vw - calc(390px / 2));
  }

  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.57);
  }
}
