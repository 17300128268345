//COLORS
$COLOR_BACKGRAUND: #ffeeee;
$COLOR_GRAY: #dfdfdf;
$COLOR_BLACK: #9799a6;
$COLOR_MORE_GRAY: rgb(223, 223, 223);
$COLOR_TEXT: #494a52;
$COLOR_TEXT_BLACK: #9799a6;

//FONT-SIZES
$FONT_MAIN_TEXT: 20px;
$FONT_TITLE: 40px;
$FONT_TITLE_SMALL: 25px;
$FONT_SMALL: 16px;

//DESKSTOP
$FONT_MAIN_TEXT_DESKSTOP: 16px;
$FONT_TITLE_DESKSTOP: 36px;
$FONT_TITLE_SMALL_DESKSTOP: 21px;
$FONT_SMALL_DESKSTOP: 12px;

//LAPTOP
$FONT_MAIN_TEXT_LAPTOP: 14px;
$FONT_TITLE_LAPTOP: 30px;
$FONT_TITLE_SMALL_LAPTOP: 19px;
$FONT_SMALL_LAPTOP: 10px;

//MOBILE
$FONT_MAIN_TEXT_MOBILE: 12px;
$FONT_TITLE_MOBILE: 25px;
$FONT_TITLE_SMALL_MOBILE: 16px;
$FONT_SMALL_MOBILE: 10px;
