.input {
  background-color: inherit;
  border-bottom: 1px solid #ede4e8;
  width: 146px;
}
.countriesFilter {
  box-shadow: 0px -14px 33px -11px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  width: 100%;
  padding-left: 6px;
  background-color: #f8f3f5;
  border-bottom: 1px solid #ede4e8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.selector {
  width: 160px;
  height: 300px;
  overflow: auto;
  background-color: #f8f3f5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    width: 3px; /* ширина скроллбара */
    height: 10px; /* высота скроллбара для горизонтального скроллинга */
  }

  /* Стилизация фона скроллбара (трека) */
  &::-webkit-scrollbar-track {
    background: #f8f3f5; /* цвет фона */
    border-radius: 10px; /* закругление углов */
  }

  /* Стилизация самого ползунка скроллбара */
  &::-webkit-scrollbar-thumb {
    background: #888; /* цвет ползунка */
    border-radius: 10px; /* закругление углов */
  }

  /* Изменение цвета ползунка при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* цвет ползунка при наведении */
  }
}

.phoneInputContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;

  &.reversed {
    direction: ltr;
    justify-content: flex-end;
  }
}
.selectorToggle {
  width: 20px;
  height: 20px;
  display: flex;
  gap: 3px;
  align-items: center;
}
.arrow {
  transition: transform 1ms ease;
  width: 9px;
  height: 9px;
}
.arrowUp {
  animation: rotateAnimation 0.2s ease forwards;
}
@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
