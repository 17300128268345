.mainContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (min-width: 768px) {
    margin: 0;
    gap: 20px;
  }
}
