@use 'styles' as *;

.container {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;

  @media (min-width: 768px) {
  }
}

.formRegistration{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1410px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 50px 0px rgba(135, 59, 57, 0.15);
  padding: 22px 0 22px 22px;
  @media (max-width: 1300px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.imgSignUp{
  width: 46%;
  height: 741px;
  border-radius: 20px;
  object-fit: cover;
  filter: sepia(0) hue-rotate(-14deg) saturate(1.2) brightness(1);
  @media (max-width: 1200px) {
    width: 30%;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.MainScreenSignInContainer{
  width: 100%;
  max-width: 519px;
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: 1;
  h1{
    color: #51473C;
    font-family: Montserrat, sans-serif;
    font-size: clamp(20px, 3vw, 40px);
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    @media (max-width: 1200px) {
      text-align: center;;
    }
  }
  @media (max-width: 1000px) {
    margin: auto 30px;
  }
  @media (max-width: 768px) {
    width: 98%;
    margin: 0 auto 0 0;
    background: #FFF;
    box-shadow: 0px 10px 50px 0px rgba(135, 59, 57, 0.15);
    max-width: 100%;
  }
}

.conainerForm {
  width: 100%;
  margin-top: min(70px, 10%);
  display: flex;
  flex-direction: column;
  gap: 29px;

  @media (max-width: 768px) {
    gap: 20px;
  }
}

.inputWrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button{
  border-radius: 49.953px;
  background: #FF9536;
  color: #630E0E;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.button:hover{
  background: #FF9536;
}

.goToSignUp{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
  p{
    width: fit-content;
    color: #51473C;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .link{
    width: fit-content;
    color: #FF9536;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    border: none;
    background: none;
    text-transform: none;
  }
}